import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { blue, grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

export const StyledTableCellMain = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: blue[900],
        // backgroundColor: grey[200],
        color: theme.palette.common.white,
        fontSize:'.8rem',
        padding:10
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: '.8rem',
        padding:10,
        fontWeight:'light'
    },
    [`&.${tableCellClasses.footer}`]: {
        fontSize: '.7rem',
        padding:10
    }
}));