import { Box, Button, Fade, Grid, Stack, Typography } from '@mui/material'
import { blue, green, grey } from '@mui/material/colors'
import React, { useEffect, useState } from 'react'
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { getLinks } from '../Links/ManageLinksRequests';

// media query
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Model from '../../assets/img/model-svg.svg';
import CometLoading from '../CustomLoader/CometLoading';
import Grow from '@mui/material/Grow';
export const Main = () => {
    // media query
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const [links,setLinks] = useState([]);
    const [isLoading,setIsLoading] = useState(true)
    useEffect(()=>{
        _init();
    },[])
    const _init = async () => {
        const res = await getLinks()
        setLinks(res.data.data)
        setIsLoading(false)
    }
    // const [links,setLinks] = useState([
    //     {
    //         name:'PENRO (ADN)',
    //         link:'http://edats-adn.denrcaraga.ph/'
    //     },
    //     {
    //         name:'PENRO (ADS)',
    //         link:'http://edats-ads.denrcaraga.ph/'
    //     },
    //     {
    //         name:'PENRO (SDN)',
    //         link:'http://edats-sdn.denrcaraga.ph/'
    //     },
    //     {
    //         name:'PENRO (SDS)',
    //         link:'http://edats-sds.denrcaraga.ph/'
    //     },
    //     {
    //         name:'PENRO (Dinagat Island)',
    //         link:'http://edats-pdi.denrcaraga.ph/'
    //     },
    //     {
    //         name:'CENRO (Bayugan)',
    //         link:'https://edats-bayugan.denrcaraga.ph/'
    //     }
    // ])
    const [selectedLink,setSelectedLink] = useState(null)
    const handleChange = (e,val) => {
        setSelectedLink(val)
        if(val){
            window.open(val.url, "_blank")
        }

    }
    return (
        <Box>
            {
                isLoading
                ?
                <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',height:'100dvh'}}>
                    <CometLoading text='Loading eDATS'/>
                </Box>
                :
                <Grow
                    in={!isLoading}
                    {...(!isLoading ? { timeout: 1000 } : {})}
                    >
                    <Grid container spacing={3}>
                        <Grid item container xs={12} md={5} lg={5}>
                            <Grid item xs={12} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <img src={Model} style={{width:matches?'200px':'600px',height:'auto'}}/><br/>
                            </Grid>
                            <Grid item xs={12} sx={{display:'flex',justifyContent:'flex-end'}}>
                                <a style={{textDecoration:'none',color:blue[400],fontSize:matches?'.6rem':'.7rem'}} target='_BLANK' href="https://storyset.com/online">Online illustrations by Storyset</a>
                            </Grid>
                        </Grid>

                        <Grid item container xs={12} md={6} lg={6} sx={{display:'flex',justifyContent:'center',alignContent:'center',alignItems:'center'}} spacing={2}>
                            <Grid item xs={12}>
                                <Typography className='header-color' sx={{typography:{sx:'h3',sm:'h3',md:'h3',lg:'h3'},textAlign:'center'}}>Enhance Document Action Tracking System Directory<br/>(eDATS)</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    disablePortal
                                    options={links.sort((a, b) => -b.group_name.localeCompare(a.group_name))}
                                    getOptionLabel={(val)=>val.name}
                                    groupBy={(option) => option.group_name}
                                    sx={{background:'#fff',p:1}}
                                    value = {selectedLink}
                                    onChange={(e,newVal)=>handleChange(e,newVal)}
                                    renderInput={(params) => <TextField sx={{background:'#fff'}} {...params} label="I want to browse the eDATS of..." />}
                                    fullWidth
                                    
                                />

                            </Grid>
                        </Grid>
                        
                        {/* <Grid item xs={12} sx={{display:'flex',justifyContent:'space-around',alignItems:'center'}}>
                            <Button variant='contained' sx={{height:50}} size='large' endIcon={<TrendingFlatIcon/>}>CENRO</Button>
                            <Button variant='contained' sx={{height:50}} size='large' endIcon={<TrendingFlatIcon/>}>PENRO</Button>
                            <Button variant='contained' sx={{height:50}} size='large' endIcon={<TrendingFlatIcon/>}>Regional</Button>
                        </Grid> */}
                    </Grid>
                </Grow>
                
            }
        </Box>
        

    )

}