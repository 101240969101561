import Swal from 'sweetalert2';

export function APISuccess(title,text){
    Swal.fire({
        icon:'success',
        title:title,
        text:text
    });
}
export function APIError(title,text){
    Swal.fire({
        icon:'error',
        title:title,
        text:text
    });
}
export function APILoading(icon,title,text){
    Swal.fire({
        icon:icon,
        title:title,
        text:text,
        showConfirmButton:false
    });
    Swal.showLoading()
}