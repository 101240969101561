import axios from "axios";

export function getLinks(){
    return axios.request({
        method:'GET',
        url:'/api/edats/getLinks'
    });
}
export function getAllLinks(){
    return axios.request({
        method:'GET',
        url:'/api/edats/getAllLinks'
    });
}
export function addLinks(data){
    return axios.request({
        method:'POST',
        data:data,
        url:'/api/edats/addLinks'
    });
}
export function deleteLinks(data){
    return axios.request({
        method:'POST',
        data:data,
        url:'/api/edats/deleteLinks'
    });
}
export function updateLinks(data){
    return axios.request({
        method:'POST',
        data:data,
        url:'/api/edats/updateLinks'
    });
}
export function login(data){
    return axios.get('/sanctum/csrf-cookie').then(response => {
        return axios.request({
            method:'POST',
            data:data,
            url:'/api/user/login'
        });
    });
    
}