import { Button, Grid, TextField } from '@mui/material'
import React, { useState } from 'react'
import { login } from '../ManageLinksRequests'
import { APIError, APILoading } from '../../CustomReturn/APIReturn'
import Swal from 'sweetalert2'
export const LoginModal = ({setLoading,close}) => {
    const [userName,setUserName] = useState('')
    const [password,setPassword] = useState('')
    const handleLogin = async (e) => {
        e.preventDefault();
        try{
            let t_data = {
                username:userName,
                password:password
            }
            APILoading('info','Verifying info','Please wait...')
            const res = await login(t_data);
            console.log(res.data)
            if(res.data.status === 200){
                localStorage.setItem('edats_token',res.data.token);
                localStorage.setItem('id', res.data.id)
                setLoading(false)
                close();
                Swal.close();
            }else{
                APIError(res.data.message)
    
            }
        }catch(err){
            APIError(err)
        }
        
    }
    return (
        <>
        <form onSubmit={handleLogin}>
        <Grid container sx={{p:1}} spacing={2}>
            <Grid item xs={12}>
                <TextField label='Username' autoFocus value={userName} onChange={(val)=>setUserName(val.target.value)} fullWidth required/>
            </Grid>
            <Grid item xs={12}>
                <TextField label='Password' type='password' value={password} onChange={(val)=>setPassword(val.target.value)} fullWidth required/>
            </Grid>
            <Grid item xs={12} sx={{display:'flex',justifyContent:'flex-end',gap:1}}>
                <Button variant='contained' className='custom-roundbutton' type='submit'>Login</Button>
            </Grid>
        </Grid>
        </form>

        </>
    )

}