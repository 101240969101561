import * as React from "react";
// import * as ReactDOM from "react-dom/client";
import './App.css';
import { Main } from './components/Main/Main';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { ManageLinks } from "./components/Links/ManageLinks";
import axios from 'axios';
import { NotFound } from "./components/NotFound/NotFound";
import { Maintenance } from "./components/Maintenance/Maintenance";

axios.defaults.baseURL = "https://endpoint.e-dats.info" //uncomment for production
// axios.defaults.baseURL = "http://localhost/denr-endpoints" //uncomment for development
// axios.defaults.baseURL = window.location.protocol + '//' + window.location.hostname + '/hris-back-end/public'
// axios.defaults.baseURL = window.location.protocol + '//' + window.location.hostname + '/hris-back-end-mobile/public'
// axios.defaults.baseURL = window.location.protocol + '//' + window.location.hostname + '/hris-back-end_develop/public'
axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'

axios.interceptors.request.use(config => {
  const token = localStorage.getItem('edats_token')
  config.headers.Authorization = token ? `Bearer ${token}` : ''
  
  return config
})

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main/>,
  },
  {
    path: "/dashboard",
    element: <ManageLinks/>,
  },{
    path: "/maintenance",
    element: <Maintenance/>,
  },{
    path: "*",
    exact:true,
    element: <NotFound/>,
  },
]);
const maintenanceRouter = createBrowserRouter([
  {
    path: "/",
    element: <Maintenance/>,
  },{
    path: "*",
    exact:true,
    element: <NotFound/>,
  },
]);
function App() {
  return (
    <>
    {
      process.env.REACT_APP_SITE_MODE === 'LIVE'
      ?
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
      :
      <React.StrictMode>
        <RouterProvider router={maintenanceRouter} />
      </React.StrictMode>
    }
    
    </>
  );
}

export default App;
