import { Autocomplete, Button, Grid, TextField } from '@mui/material'
import React, { useState } from 'react'
import { ModalFooter } from '../../CustomModal/ModalFooter'
import { addLinks } from '../ManageLinksRequests';
import { APIError, APILoading, APISuccess } from '../../CustomReturn/APIReturn';
export const AddData = ({setData,close}) => {
    const [name,setName] = useState('');
    const [link,setLink] = useState('');
    const [group,setGroup] = useState([
        {
            name:'Regional Office',
            group_name:'Regional Office',
            group_id:0
        },
        {
            name:'Agusan Del Norte',
            group_name:'Agusan Del Norte',
            group_id:1
        },
        {
            name:'Agusan Del Sur',
            group_name:'Agusan Del Sur',
            group_id:2
        },
        {
            name:'Surigao Del Norte',
            group_name:'Surigao Del Norte',
            group_id:3
        },
        {
            name:'Surigao Del Sur',
            group_name:'Surigao Del Sur',
            group_id:4
        },
        {
            name:'Province of Dinagat Island',
            group_name:'Province of Dinagat Island',
            group_id:5
        },
    ])
    const [selectedGroup,setSelectedGroup] = useState(null)
    const handleSave = async (e) => {
        e.preventDefault();
        try{
            APILoading('info','Adding new data','Please wait...')
            let t_data = {
                name:name,
                link:link,
                group_name:selectedGroup.group_name,
                group_id:selectedGroup.group_id
            }
            const res = await addLinks(t_data)
            if(res.data.status === 200){
                APISuccess(res.data.message)
                setData(res.data.data)
                setName('')
                setLink('')
                setSelectedGroup(null)
    
            }else{
                APIError(res.data.message)
            }
        }catch(err){
            APIError(err)
        }
        
    }
    return(
        <form onSubmit={handleSave}>
        <Grid container spacing={2} sx={{p:1}}>
            <Grid item xs={12}>
                <TextField label = 'Name' value={name} onChange = {val=>setName(val.target.value)} fullWidth required/>
            </Grid>
            <Grid item xs={12}>
                <TextField label = 'Link' type='link' value={link} onChange = {val=>setLink(val.target.value)} fullWidth required/>
            </Grid>
            <Grid item xs={12} sx={{display:'flex',justifyContent:'center'}}>
                <Autocomplete
                    disablePortal
                    options={group}
                    getOptionLabel={(val)=>val.name}
                    value = {selectedGroup}
                    onChange={(e,newVal)=>setSelectedGroup(newVal)}
                    renderInput={(params) => <TextField {...params} label="Under" />}
                    fullWidth
                />

            </Grid>
            <ModalFooter handleClose={close} type={1}/>
        </Grid>
        </form>

    )

}