import { Autocomplete, Button, Grid, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ModalFooter } from '../../CustomModal/ModalFooter'
import { addLinks, updateLinks } from '../ManageLinksRequests';
import { APIError, APILoading, APISuccess } from '../../CustomReturn/APIReturn';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
export const UpdateData = ({setData,close,data}) => {
    const [name,setName] = useState('');
    const [link,setLink] = useState('');
    const [enabled,setEnabled] = useState(false)
    const [group,setGroup] = useState([
        {
            name:'Agusan Del Norte',
            group_name:'Agusan Del Norte',
            group_id:1
        },
        {
            name:'Agusan Del Sur',
            group_name:'Agusan Del Sur',
            group_id:2
        },
        {
            name:'Surigao Del Norte',
            group_name:'Surigao Del Norte',
            group_id:3
        },
        {
            name:'Surigao Del Sur',
            group_name:'Surigao Del Sur',
            group_id:4
        },
        {
            name:'Province of Dinagat Island',
            group_name:'Province of Dinagat Island',
            group_id:5
        },
    ]);
    
    const [selectedGroup,setSelectedGroup] = useState(null)
    useEffect(()=>{
        setName(data.name);
        setLink(data.url);
        setEnabled(data.enabled)
        const filter = group.filter(el=>el.group_id === data.group_id)
        if(filter.length>0){
            setSelectedGroup(filter[0])
        }
    },[data])
    const handleSave = async (e) => {
        e.preventDefault();
        try{
            APILoading('info','Updating data','Please wait...')
            let t_data = {
                id:data.edats_links_id,
                name:name,
                enabled:enabled,
                link:link,
                group_name:selectedGroup.group_name,
                group_id:selectedGroup.group_id
            }
            const res = await updateLinks(t_data)
            if(res.data.status === 200){
                APISuccess(res.data.message)
                setData(res.data.data)
                setName('')
                setLink('')
                setSelectedGroup(null)
    
            }else{
                APIError(res.data.message)
            }
        }catch(err){
            APIError(err)
        }
        
    }
    const handleChange = (event) => {
        setEnabled(event.target.checked);
    };
    return(
        <form onSubmit={handleSave}>
        <Grid container spacing={2} sx={{p:1}}>
            <Grid item xs={12}>
                <TextField label = 'Name' value={name} onChange = {val=>setName(val.target.value)} fullWidth required/>
            </Grid>
            <Grid item xs={12}>
                <TextField label = 'Link' type='link' value={link} onChange = {val=>setLink(val.target.value)} fullWidth required/>
            </Grid>
            <Grid item xs={12} sx={{display:'flex',justifyContent:'center'}}>
                <Autocomplete
                    disablePortal
                    options={group}
                    getOptionLabel={(val)=>val.name}
                    value = {selectedGroup}
                    onChange={(e,newVal)=>setSelectedGroup(newVal)}
                    renderInput={(params) => <TextField {...params} label="Under" />}
                    fullWidth
                />

            </Grid>
            <Grid item xs={12}>
                <FormControlLabel control={<Switch checked={enabled} onChange={handleChange} />} label="Enabled" />
            </Grid>
            <ModalFooter handleClose={close} type={1}/>
        </Grid>
        </form>

    )

}