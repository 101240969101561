import { Grid, Typography } from '@mui/material'
import React from 'react'
import NotExists from '../../assets/img/404.svg';
import { blue } from '@mui/material/colors';
export const NotFound = () => {
    return(
        <Grid container>
            <Grid item xs={12} sx={{overflow:'hidden',height:'100dvh',width:'100dvw'}}>
                <img src={NotExists} style={{height:'100dvh',width:'100dvw'}}/>
                <a href="https://storyset.com/web" style={{textDecoration:'none',color:blue[500],fontSize:'.8rem',position:'absolute',bottom:0,right:'10px'}}>Web illustrations by Storyset</a>
            </Grid>
        </Grid>
    )

}