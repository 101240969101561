import { Button, Grid } from '@mui/material'
import React from 'react'
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
export const ModalFooter = ({handleSave,handleClose,type}) => {
    return (
        <Grid item xs={12} sx={{display:'flex',gap:1,justifyContent:'flex-end'}}>
            {
                type === 1
                ?
                <Button variant='contained' startIcon={<CheckIcon/>} className='custom-roundbutton' color='success' type='submit'>Save</Button>
                :
                <Button variant='contained' startIcon={<CheckIcon/>} className='custom-roundbutton' color='success' onClick={handleSave}>Save</Button>
            }
            <Button variant='contained' startIcon={<CloseIcon/>} className='custom-roundbutton' color='error' onClick={handleClose}>Cancel</Button>
        </Grid>

    )

}