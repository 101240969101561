import { Box, Button, Grid, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { deleteLinks, getAllLinks } from './ManageLinksRequests'
//Icons
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

//Custom Modals
import SmallModal from '../CustomModal/SmallModal';

import { AddData } from './modals/AddData';
import Swal from 'sweetalert2';
//API response alert
import { APIError, APILoading, APISuccess } from '../CustomReturn/APIReturn';
import { StyledTableCellMain } from '../CustomTable/MainTable';
import { UpdateData } from './modals/UpdateData';
import { LoginModal } from './modals/LoginModal';
// media query
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Link } from "react-router-dom";
export const ManageLinks = () => {
    // media query
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const user = localStorage.getItem('edats_token') === null ? '' : localStorage.getItem('edats_token');
    const [data,setData] = useState([])
    const [selectedData,setSelectedData] = useState([])
    const [loading,setLoading] = useState(true)
    const [openLoginModal,setOpenLoginModal] = useState(false)
    useEffect(()=>{
        if(user){
            _init();
        }else{
            setOpenLoginModal(true)
        }
    },[user])
    const _init = async () => {
        const res = await getAllLinks()
        setData(res.data.data)
        setLoading(false)
    }
    const [openModal,setOpenModal] = useState(false)
    const [openUpdateModal,setOpenUpdateModal] = useState(false)
    const handleDelete = (item) => {
        console.log(item)
        Swal.fire({
            icon:'question',
            title:'Confirm delete ?',
            text:'Action can not be revered',
            showCancelButton:true,
            confirmButtonText:'Confirm',
            confirmButtonColor:'red',
            cancelButtonText:'Cancel'
        }).then(async res=>{
            if(res.isConfirmed){
                    try{
                        APILoading('info','Deleting data','Please wait...');
                        const res = await deleteLinks({id:item.edats_links_id})
                        if(res.data.status === 200){
                            APISuccess(res.data.message);
                            setData(res.data.data)
                        }else{
                            APIError(res.data.message);
                        }
                    }catch(err){
                        APIError(err)
                    }
            }

        })
    }
    const [searchVal,setSearchVal] = useState('')
    const filter = data.filter(el=>el.name.toUpperCase().includes(searchVal.toUpperCase()))
    const handleUpdate = (item) => {
        console.log(item)
        setSelectedData(item)
        setOpenUpdateModal(true)

    }
    return (
        <>
            {
                loading
                ?
                ''
                :
                <Grid container spacing={1} sx={{p:matches?2:10,display:'flex',justifyContent:'center',alignItems:'center'}}>
                <Grid item xs={12} sx={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                    <Box>
                        <Link to="/" relative="path">
                            <Button startIcon={<ArrowBackIcon/>}>Home</Button>
                        </Link>
                    </Box>
                    <Box sx={{display:'flex',gap:1,justifyItems:'center'}}>
                        <TextField label ='Search table' value={searchVal} onChange={(val)=>setSearchVal(val.target.value)} size='small' slotProps={{
                        input: {
                            endAdornment: (
                            <InputAdornment position="end">
                                <Tooltip title='Clear Search'>
                                <IconButton size='small' onClick={()=>setSearchVal('')}>
                                    <CloseIcon />
                                </IconButton>
                                </Tooltip>
                            </InputAdornment>
                            ),
                        },
                        }}/>
                        <Tooltip title='Add new data'>
                            <Button variant='contained' color='primary' className='custom-roundbutton' startIcon={<AddIcon/>} onClick={()=>setOpenModal(true)}>Add</Button>
                        </Tooltip>
                    </Box>
                    
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <TableContainer sx={{maxHeight:'70dvh'}}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCellMain>
                                            Name
                                        </StyledTableCellMain>
                                        <StyledTableCellMain>
                                            URL
                                        </StyledTableCellMain>
                                        <StyledTableCellMain align='center'>
                                            Group Name
                                        </StyledTableCellMain>
                                        <StyledTableCellMain align='center'>
                                            Actions
                                        </StyledTableCellMain>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        filter.length>0
                                        ?
                                            filter.map((item)=>{
                                                return (
                                                    <TableRow key = {item.edats_links_id} hover>
                                                        <StyledTableCellMain>
                                                            {item.name}
                                                        </StyledTableCellMain>
                                                        <StyledTableCellMain>
                                                            <a title='Click to view URL' href={item.url} target='_BLANK'>{item.url}</a> 
                                                        </StyledTableCellMain>
                                                        <StyledTableCellMain>
                                                            {item.group_name}
                                                        </StyledTableCellMain>
                                                        <StyledTableCellMain align='center' sx={{display:'flex',gap:1,justifyContent:'center'}}>
                                                            <Tooltip title='Update data'><IconButton variant='contained' color='success' size='small' onClick={()=>handleUpdate(item)}><EditIcon/></IconButton></Tooltip>
                                                            <Tooltip title='Delete data'><IconButton variant='contained' color='error' size='small' onClick={()=>handleDelete(item)}><DeleteIcon/></IconButton></Tooltip>
                                                        </StyledTableCellMain>
                                                    </TableRow>

                                                )

                                            })
                                        :
                                        <TableRow>
                                            <TableCell colSpan={4} align='center'>
                                                No Data
                                            </TableCell>
                                        </TableRow>

                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
                <SmallModal open={openModal} close={()=>setOpenModal(false)} title='Adding New Data'>
                    <AddData setData={setData} close={()=>setOpenModal(false)}/>
                </SmallModal>
                <SmallModal open={openUpdateModal} close={()=>setOpenUpdateModal(false)} title='Updating Data'>
                    <UpdateData setData={setData} close={()=>setOpenUpdateModal(false)} data = {selectedData}/>
                </SmallModal>
            </Grid>
            }
            <SmallModal open={openLoginModal} close={()=>setOpenLoginModal(false)} title='Login' outsideClose={false}>
                <LoginModal setLoading={setLoading} close={()=>setOpenLoginModal(false)}/>
            </SmallModal>
        </>
        

    )

}